import Application from './components/application'
import objectFitPolyfill from 'objectFitPolyfill'
import tippy from 'tippy.js'

$(document).ready(function() {
  var buttonsContainer = $('.some-selector');

  const app = new Application();

  app.scrollTo();
  app.offCanvasMenu();

  if (typeof objectFitPolyfill === 'function') {
    objectFitPolyfill();
  }

  $('#js-accordion').accordionjs();
});

