'use strict';

class Application {
  constructor() {
    this.stickyHeaderElement = $(".js-sticky-header");
    this.headerLogoElement = $(".js-header-logo");
    this.videoPopupButton = $(".js-toggle-video-popup");
    this.videoPopupElement = $(".js-video-popup");

    this.bindEvents();
    this.stickyHeader();
    this.showInsurancePlan();
    this.enableInsuranceWizard();
  }

  bindEvents() {
    const self = this;
    this.headerHeight = this.stickyHeaderElement.outerHeight();

    $(window).scroll(function() {
      self.stickyHeader();
    });

    this.videoPopupButton.on("click", function(e) {
      e.preventDefault();
      self.openVideoPopup();
    });

    $(window).resize(self.debounce(function() {
      self.adjustPopup(self.videoPopupElement);
    }));

    $(document).on("click", ".black-tarp, .js-close-video-popup", function(e) {
      e.preventDefault();
      self.closeVideoPopup();
    });

    $(document).on("click", ".contract__header", function(e) {
      e.preventDefault();
      $(this).parents(".js-contract-toggle").toggleClass("contract--toggled");
    });
  }

  enableInsuranceWizard() {
    $('.insured_person_template :input').attr('disabled', 'disabled');

    $(document).on("click", ".tools a.add-person", function(e){
      e.preventDefault();
      var elem = $($('.insured_person_template').html());
      elem.find(":input").attr('disabled', false);
      $('.insured_persons').append(elem);
    })

    $(document).on("click", ".insured_persons .tools a.remove-person", function(e) {
      e.preventDefault();
      $(this).closest('.grid').remove();
    })
  }

  stickyHeader() {
    let header_element = $(".main-header");
    let window_top = $(window).scrollTop();
    let top_position = $('body').offset().top;

    if (window_top > top_position) {
      header_element.css("paddingTop", this.headerHeight);
      this.stickyHeaderElement.addClass('head-bar--sticky');
      this.headerLogoElement.attr('src', this.headerLogoElement.data('original'));
    } else {
      this.headerLogoElement.attr('src', this.headerLogoElement.data('negative'));
      header_element.css("paddingTop", 0);
      this.stickyHeaderElement.removeClass('head-bar--sticky');
    }
  }

  openVideoPopup() {
    this.videoPopupElement.fadeIn();

    if ($(".black-tarp").length === 0) {
      $("body").append("<div class='black-tarp'></div>");
    } else {
      $(".black-tarp").fadeIn();
    }

    this.adjustPopup(this.videoPopupElement);
  }


  adjustPopup(element) {
    let popupMargin = element.find(".popup-wrapper").outerHeight() / 2;
    element.css('marginTop', 0 - popupMargin);
  }

  closeVideoPopup() {
    this.videoPopupElement.fadeOut();
    $(".black-tarp").hide();

    // Destroy Video
    let url = this.videoPopupElement.find('iframe').attr('src');
    this.videoPopupElement.find('iframe').attr('src', '');
    this.videoPopupElement.find('iframe').attr('src', url);
  }

  debounce(func, wait, immediate) {
    let timeout;
    return function() {
      let context = this,
        args = arguments;
      let later = function() {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      let callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }

  offCanvasMenu() {
    const $toggler = $('.js_toggle-menu');
    const offcanvas = '.offcanvas';

    $('.main-navigation').clone().appendTo(offcanvas);
    $('.foot-nav').clone().appendTo(offcanvas);
    $(offcanvas).find('.fa-globe').closest('li').remove();
    $('.foot-section--copyrights').find('span').first().clone().appendTo('.offcanvas').addClass('copyright');

    $('.offcanvas').find('.nav-items__item--has-submenu').append('<i class="fa fa-angle-right" aria-hidden="true"></i>');

    $('.fa-angle-right').on('click', function() {
      const $this = $(this);
      const $nextLvl = $this.siblings('.nav-items__lvl');
      const $link = $this.siblings('a');

      $nextLvl.toggleClass('is-active');
      $this.toggleClass('is-active');
      $link.toggleClass('is-active');
    });

    $toggler.unbind().on('click', function() {
      const $this = $(this);
      const $nav = $('.offcanvas');
      const navIsVisible = 'nav--is-visible';
      const stopScroll = 'stop-scroll';

      $this.find('a i').toggleClass('fa-bars fa-close');

      $('.nav-items').toggleClass('grid--justify-end grid--justify-start');

      $nav.toggleClass('is--open');
      $('.offcanvas-wrapper').toggleClass(navIsVisible);
      $('.head-bar--sticky').toggleClass(navIsVisible);
      $('html').toggleClass(stopScroll);
    });

    $('.main-header .main-navigation li:has(ul)').doubleTapToGo();
  }

  scrollTo() {
    const $toggler = $('*[data-jump-to]');

    $toggler.on('click', function() {
      const $target = $(this).attr('data-jump-to');
      let offset = $(this).attr('data-jump-to-offset') || 0;

      $('html,body').animate({
        scrollTop: $($target).offset().top - offset
      }, 'slow');
    })
  }

  showInsurancePlan() {
    const READONLY_CLASS = 'insurance-plan--readonly';
    const ACTIVE_CLASS = 'insurance-plan--highlighted';
    const INSURANCE_PLAN_CLASS = 'insurance-plan';

    const $insurancePlans = $(`.${INSURANCE_PLAN_CLASS} :checkbox`);

    $insurancePlans.on('change', function () {
      togglePlan(this);
    });

    $insurancePlans.trigger( 'change' );

    function togglePlan(el) {
      $(el).closest(`.${INSURANCE_PLAN_CLASS}`).toggleClass(ACTIVE_CLASS);
      validateCombinations()
    }

    function validateCombinations() {
      const activePlans = $insurancePlans
        .filter((idx, el) => el.checked).toArray();

      const availableTriggers = $insurancePlans
        .toArray().map(plan => plan.getAttribute('data-trigger'));

      const $inactivePlans = $insurancePlans.not(activePlans);

      const validCombinations = activePlans
        .map(el => el.getAttribute('data-combinations').split(','))
        .reduce((intersection, ary) => (intersection.filter(m => ary.indexOf(m) > -1)), availableTriggers);

      $inactivePlans.each(function (idx, el) {
        if (validCombinations.indexOf(el.getAttribute('data-trigger')) === -1) {
          disablePlan(el);
        } else {
          enablePlan(el);
        }
      });
    }

    function disablePlan(el) {
      $(el).prop('disabled', 'disabled')
            .closest(`.${INSURANCE_PLAN_CLASS}`)
            .addClass(READONLY_CLASS)
    }

    function enablePlan(el) {
      $(el).prop('disabled', '')
            .closest(`.${INSURANCE_PLAN_CLASS}`)
            .removeClass(READONLY_CLASS)
    }
  }
};

export default Application;
